import { FC, useRef, useState } from 'react';
import { Spin } from 'antd';
import { IRouteComponentProps, history } from 'umi';
import { useRequest } from 'ahooks';
import { StatusBox, Button, Input, TextArea, message } from 'iglooform';
import { SearchOutlined } from 'iglooicon';
import moment from 'moment';
import {
  getMultiLingualResource,
  saveMultiLingualResource,
} from '../../../service';
import { userInfoState } from '@/store';
import { useRecoilValue } from 'recoil';
import BackTo from '@/components/back-to';
import Card from '@/components/card';
import Table from '@/components/table';
import styles from './index.less';
import BackTop from '@/components/back-top';
import { moveUntranslatedToFront } from '@/utils';
interface lang {
  contentReference: string;
  id: number;
  lastTranslatedAt: string;
  lastTranslator: string;
  name: string;
  target: string;
}

const ResourceStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  TRANSLATING: 'waiting',
  REVIEWING: 'warning',
  READY: 'success',
};

const EditMultiResource: FC<
  IRouteComponentProps<{ id: string; language: string }>
> = ({ match }) => {
  const userInfo = useRecoilValue(userInfoState);
  const [langResource, setResource] = useState<lang[]>([]);
  const [resourceStatus, setStatus] = useState('');
  const [editingIndex, setEditingIndex] = useState<any>(undefined);
  const [searchingWord, setSearchingWord] = useState('');
  const [haveEditedIndex, setHaveBeenEdited] = useState<number[]>([]);
  const [noPermissionLoading, setNoPermissionLoading] = useState(false);
  const fileInputRef = useRef<any>(null);

  const { email: currentUser, permissions } = userInfo;

  const projectId = match.params.id;
  const lang = match.params.language;
  const buttons = [
    <Button
      style={{ width: 140 }}
      onClick={() => {
        saveResource(
          projectId,
          lang,
          langResource.filter((_, index) => {
            return haveEditedIndex.includes(index);
          }),
        );
      }}
      key={1}
      type="primary"
    >
      Save
    </Button>,
  ];
  function importFile() {
    const input = fileInputRef.current;
    const file = input.files[0];
    const { type } = file;
    if (!type.includes('json')) {
      message.error('Only support json file');
      return;
    }
    const reader = new FileReader();
    reader.readAsText(file);
    reader.onerror = function () {
      message.error('Please check file content');
    };
    reader.onload = function (e) {
      const result = JSON.parse(e.target?.result as string);

      const editedIndex: number[] = [];
      const mergedData = langResource.map((lang, index) => {
        return {
          ...lang,
          target: lang.target || result[lang.name],
        };
      });

      const sortData = moveUntranslatedToFront(mergedData).map(
        (item, index) => {
          if (!item.target) {
            editedIndex.push(index);
          }
          return item;
        },
      );

      setResource(sortData);
      setHaveBeenEdited(editedIndex);
    };
    // 重置input的value才能让下次点击同一个文件地址的时候触发onchange事件
    fileInputRef.current.value = null;
  }

  if (permissions?.includes('Admin')) {
    buttons.unshift(
      <Button
        style={{ width: 140 }}
        onClick={() => {
          const ref = fileInputRef.current;
          ref.onchange = importFile;
          ref.click();
        }}
        key={1}
      >
        Import
      </Button>,
    );
  }

  const { loading, run: refreshList } = useRequest<{
    status: string;
    resources: lang[];
    translatorEmail: string;
  }>(getMultiLingualResource, {
    onSuccess: (res) => {
      const { resources, status = '', translatorEmail } = res;
      const sortData = moveUntranslatedToFront(resources);
      setStatus(status);

      setResource(sortData.map((res, index) => ({ ...res, key: index })));

      if (translatorEmail !== currentUser || status !== 'TRANSLATING') {
        setNoPermissionLoading(true);

        message.warning(
          translatorEmail === currentUser
            ? `${lang} resource can't be edit in current status`
            : "You don't have the permission of this page,will return back",
          1.5,
        );
        setTimeout(() => {
          setNoPermissionLoading(false);
          history.replace(`/projects/${projectId}/multi-lingual`);
        }, 1500);
      }
    },
    defaultParams: [projectId, lang],
  });

  const { loading: saveLoading, run: saveResource } = useRequest(
    saveMultiLingualResource,
    {
      manual: true,
      onSuccess: () => {
        message.success(`${lang} resource saved successfully`);
        refreshList(projectId, lang);
        setHaveBeenEdited([]);
      },
    },
  );

  const columns = [
    {
      title: 'No',
      dataIndex: 'id',
      render: (id: number, row: any, index: number) => index + 1,
    },
    {
      title: 'Item(EN)',
      dataIndex: 'name',
      className: styles.tableColumn,
    },
    {
      title: 'Context Reference',
      dataIndex: 'contextReference',
    },
    {
      title: `Target-${lang}`,
      dataIndex: 'target',
      className: styles.tableColumn,
      onCell: (_: any, rowIndex: number | undefined) => {
        if (rowIndex === editingIndex) {
          return {
            className: styles.targetCol,
          };
        }
        return {
          onClick: () => setEditingIndex(rowIndex),
        };
      },
      render: (target: string, row: any, index: number) => {
        const { key } = row;
        return index === editingIndex ? (
          <TextArea
            style={{ minWidth: 220 }}
            autoFocus
            defaultValue={target}
            onChange={() => {
              const newArr = [...haveEditedIndex];
              if (!newArr.includes(key)) {
                newArr.push(key);
                setHaveBeenEdited(newArr);
              }
            }}
            onBlur={(e) => {
              const newResource = [...langResource];
              newResource[key].target = e.target.value;
              setResource(newResource);
              setEditingIndex(undefined);
            }}
          />
        ) : (
          target
        );
      },
    },
    {
      title: 'Last Translated',
      dataIndex: 'lastTranslatedAt',
      render: (time: number) =>
        time ? moment(time).format('DD/MM/YYYY HH:mm:ss') : '',
    },
    {
      title: 'Last Translator',
      dataIndex: 'lastTranslator',
    },
  ];

  const handleFilter = (keyword: string) => {
    setSearchingWord(keyword);
  };

  // 统一从langResource这个单一数据元中获取
  const getSearchedResource = (
    searchingWord: string,
    totalResource: lang[],
  ) => {
    const filterData = totalResource.filter(({ name }) =>
      name.toLowerCase().includes(searchingWord.toLowerCase()),
    );
    return moveUntranslatedToFront(filterData);
  };

  return (
    <Spin spinning={saveLoading || noPermissionLoading}>
      <div className={styles.editContainer}>
        <BackTo
          targetName="Multi-lingual Resource"
          targetPath={`/projects/${projectId}/multi-lingual`}
        />
        <Card
          title={`${lang} Resource`}
          pageStatusIcon={
            <StatusBox type={ResourceStatus[resourceStatus]}>
              {resourceStatus}
            </StatusBox>
          }
          extraButtons={buttons}
        >
          <input ref={fileInputRef} type="file" style={{ display: 'none' }} />
          <div className={styles.top}>
            <Input
              style={{ margin: '24px 0px 0px 0px' }}
              className={styles.topInput}
              prefix={
                <SearchOutlined style={{ fontSize: 24, color: '#666666' }} />
              }
              onPressEnter={(e) => handleFilter((e.target as any).value)}
            />
          </div>
          <Table
            loading={loading}
            className={styles.table}
            dataSource={
              searchingWord === ''
                ? langResource
                : getSearchedResource(searchingWord, langResource)
            }
            columns={columns}
            rowKey="id"
          />
        </Card>
      </div>
      <BackTop />
    </Spin>
  );
};

export default EditMultiResource;
