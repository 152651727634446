import { request } from '@/utils/request';

export const getPermissions = () =>
  request('/v1/2b-account/account/permissions?space_key=Translation');

export const getLanguages = () => request('/v1/multilingual/languages');

export const getIglooUser = (roleKey: string) => {
  return request(`/v1/multilingual/accounts`, {
    params: {
      roleKey,
      preloadAccount: true,
    },
  });
};

export const createProject = (params: any) =>
  request('/v1/multilingual/projects', {
    method: 'post',
    data: params,
  });

export const updateProject = (id: number, params: any) =>
  request(`/v1/multilingual/projects/${id}`, {
    method: 'put',
    data: params,
  });
