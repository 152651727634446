import { FC, useState, useMemo, useEffect } from 'react';
import {
  CardItem,
  StatusBox,
  Input,
  Typography,
  Button,
  YellowButton,
  RedButton,
  GreenButton,
  Modal,
  AlertMessage,
} from 'iglooform';
import { SearchOutlined } from 'iglooicon';
import { message, Space, Spin } from 'antd';
import BackTo from '@/components/back-to';
import Card from '@/components/card';
import Table from '@/components/table';
import { IRouteComponentProps, history } from 'umi';
import { useRequest } from 'ahooks';
import {
  getProjectDetail,
  getMultiLingualResource,
  syncMultiLingualResource,
  complete,
  approve,
  reject,
  reassign,
} from '../../service';
import {
  otherLangResState,
  userInfoState,
  translatorState,
  reviewerState,
} from '@/store';
import { useSetRecoilState, useRecoilValue, useRecoilState } from 'recoil';
import moment from 'moment';
import styles from './index.less';
import classNames from 'classnames';
import BackTop from '@/components/back-top';
import { moveUntranslatedToFront } from '@/utils';

const ProjectStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  LOCKED: 'waiting',
  READY: 'success',
};

const ResourceStatus: { [key: string]: any } = {
  ACTIVE: 'warning',
  TRANSLATING: 'waiting',
  REVIEWING: 'warning',
  READY: 'success',
};

const ProjectDetail: FC<IRouteComponentProps<{ id: string }>> = ({ match }) => {
  const [display, setDisplay] = useState<OtherLang[]>([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const userInfo = useRecoilValue(userInfoState);
  const [resources, setResources] = useRecoilState(otherLangResState);
  const translator = useRecoilValue(translatorState);
  const reviewer = useRecoilValue(reviewerState);
  const id = match.params.id;
  const { permissions = [] } = userInfo;

  const {
    data: projectDetail,
    run: refreshDetail,
    loading,
  } = useRequest<Project>(getProjectDetail, {
    manual: false,
    defaultParams: [id],
  });

  const {
    status,
    languages,
    contextReference,
    name,
    id: projectId,
  } = projectDetail || ({} as Project);

  const defaultLang = useMemo(() => {
    if (!Array.isArray(languages)) {
      return null;
    }

    if (
      !userInfo.permissions.includes('Translate') &&
      !userInfo.permissions.includes('Review')
    ) {
      return languages[0];
    }

    for (let lang of languages) {
      if (userInfo.permissions.includes(lang)) {
        return lang;
      }
    }

    return languages[0];
  }, [userInfo, languages]);

  const [selectedLang, setLang] = useState(defaultLang);

  useEffect(() => {
    setLang(defaultLang);
  }, [defaultLang]);

  useEffect(() => {
    if (!permissions.length) return;

    const isAdmin = permissions.includes('Admin');
    setIsAdmin(isAdmin);
  }, [permissions]);

  const { run: refreshResource, loading: resLoading } =
    useRequest<MultiLingualResources>(getMultiLingualResource, {
      manual: true,
      onSuccess: (data) => {
        if (!data || !selectedLang) return;

        setResources({
          [selectedLang]: data,
        });

        const _data = data.resources || [];

        setDisplay(moveUntranslatedToFront([..._data]));
      },
    });

  useEffect(() => {
    if (!selectedLang) return;

    refreshResource(projectId, selectedLang);
  }, [selectedLang]);

  const {
    resources: dataSource,
    translatorEmail,
    reviewerEmail,
    status: resStatus,
    rejectReason,
  } = resources[selectedLang as string] || {};

  const isOwner = useMemo(
    () =>
      (resStatus === 'TRANSLATING' && translatorEmail === userInfo.email) ||
      (resStatus === 'REVIEWING' && reviewerEmail === userInfo.email),
    [translatorEmail, reviewerEmail, userInfo, resStatus],
  );

  const handleReassign = () => {
    Modal.confirm({
      title: 'Reassign',
      mode: 'form',
      className: styles.modal,
      maskClosable: false,
      formConfig: {
        type: 'Page',
        elements: [
          {
            type: 'Select',
            label: `${selectedLang} ${
              resStatus === 'TRANSLATING' ? 'Translator' : 'Reviewer'
            }`,
            name:
              resStatus === 'TRANSLATING'
                ? 'translator_email'
                : 'reviewer_email',
            options: (resStatus === 'TRANSLATING' ? translator : reviewer)[
              selectedLang as string
            ]?.map((email) => ({ label: email, value: email })),
            showSearch: true,
          },
        ],
      },
      onOk: async (values: any) => {
        await reassign(id, selectedLang as string, values);
        refreshDetail(id);
        refreshResource(id, selectedLang);
      },
    });
  };

  const projectButtons = useMemo(() => {
    const buttons = [];

    if (
      (['TRANSLATING', 'REVIEWING'].includes(resStatus) && isAdmin) ||
      (isOwner &&
        ['TRANSLATING', 'REVIEWING'].includes(resStatus) &&
        (userInfo.permissions.includes('Translate') ||
          userInfo.permissions.includes('Review')) &&
        userInfo.permissions.includes(selectedLang as string))
    ) {
      buttons.push(
        <Button className={styles.button} onClick={handleReassign}>
          Reassign
        </Button>,
      );
    }

    return buttons;
  }, [resStatus, isOwner, selectedLang]);

  const handleComplete = () => {
    Modal.confirm({
      title: 'Confirmation',
      content: `Are you sure to complete translation of ${selectedLang}.`,
      maskClosable: false,
      onOk: async () => {
        await complete(id, selectedLang as string);
        refreshDetail(id);
        refreshResource(id, selectedLang);
      },
    });
  };

  const handleReject = () => {
    Modal.confirm({
      title: 'Confirmation',
      mode: 'form',
      maskClosable: false,
      formConfig: {
        type: 'Page',
        elements: [
          {
            render: () => (
              <Typography level="body1">
                Please leave comments of rejection.
              </Typography>
            ),
          },
          {
            type: 'TextArea',
            name: 'reason',
            fullRow: true,
            messageVariables: {
              label: 'Comments',
            },
          },
        ],
      },
      onOk: async (values: any) => {
        await reject(id, selectedLang as string, values);
        refreshDetail(id);
        refreshResource(id, selectedLang);
      },
    });
  };

  const handleApprove = () => {
    Modal.confirm({
      title: 'Confirmation',
      content: `Are you sure to approve translation of ${selectedLang}.`,
      maskClosable: false,
      onOk: async () => {
        await approve(id, selectedLang as string);
        refreshDetail(id);
        refreshResource(id, selectedLang);
      },
    });
  };

  const handlePreview = () => {
    const output: any = {};
    dataSource.forEach(({ name, target }) => {
      output[name] = target;
    });

    const displayText = JSON.stringify(output, null, 2);

    const handleCopy = async () => {
      await navigator.clipboard.writeText(displayText);
      message.success('Copied to clipborad successfully!');
    };

    Modal.noFooter({
      title: 'Preview',
      maskClosable: false,
      content: (
        <div style={{ whiteSpace: 'pre-wrap' }}>
          <Button onClick={handleCopy}>Copy to Clipboard</Button>
          <div
            style={{
              marginTop: 16,
              padding: 16,
              border: '1px dashed',
              borderRadius: 3,
            }}
          >
            {displayText}
          </div>
        </div>
      ),
    });
  };

  const resourceButtons = useMemo(() => {
    const buttons = [];

    if (
      isOwner &&
      resStatus === 'TRANSLATING' &&
      (userInfo.permissions.includes('Translate') ||
        userInfo.permissions.includes('Review')) &&
      userInfo.permissions.includes(selectedLang as string)
    ) {
      buttons.push(
        <Button
          className={styles.button}
          onClick={() => {
            history.push(`${match.url}/ai/${selectedLang}`);
          }}
        >
          AI Translation
        </Button>,
        <Button
          className={styles.button}
          onClick={async () => {
            await syncMultiLingualResource(id, selectedLang);

            refreshResource(id, selectedLang);
          }}
        >
          Sync from Library
        </Button>,
        <Button
          className={styles.button}
          onClick={() => history.push(`${match.url}/edit/${selectedLang}`)}
        >
          Update
        </Button>,
      );
    }

    if (
      isOwner &&
      resStatus === 'TRANSLATING' &&
      userInfo.permissions.includes('Translate') &&
      userInfo.permissions.includes(selectedLang as string)
    ) {
      buttons.push(
        <YellowButton className={styles.button} onClick={handleComplete}>
          Complete
        </YellowButton>,
      );
    }

    if (
      isOwner &&
      resStatus === 'REVIEWING' &&
      userInfo.permissions.includes('Review') &&
      userInfo.permissions.includes(selectedLang as string)
    ) {
      buttons.push(
        <RedButton className={styles.button} onClick={handleReject}>
          Reject
        </RedButton>,
        <GreenButton className={styles.button} onClick={handleApprove}>
          Approve
        </GreenButton>,
      );
    }

    if (['ACTIVE', 'READY'].includes(resStatus) && dataSource?.length) {
      buttons.push(
        <Button className={styles.button} onClick={handlePreview}>
          Preview
        </Button>,
      );
    }

    return buttons;
  }, [resStatus, isOwner, selectedLang, dataSource]);

  const handleFilter = (keyword: string) => {
    const filterData = dataSource.filter(({ name }) =>
      name.toLowerCase().includes(keyword.toLowerCase()),
    );

    setDisplay(moveUntranslatedToFront(filterData));
  };

  const changeLang = (key: string) => {
    key !== selectedLang && setLang(key);
  };

  return (
    <Spin spinning={resLoading || loading}>
      <div className={styles.container}>
        <BackTo targetName="Project List" targetPath="/projects" />
        <Space size={16} className={styles.languages}>
          {languages?.map((lang) => (
            <div
              className={classNames({
                [styles.languageBox]: true,
                [styles.selectedLang]: lang === selectedLang,
              })}
              onClick={() => changeLang(lang)}
              key={lang}
            >
              {lang}
            </div>
          ))}
        </Space>
        <Card
          title={`Project: ${name}`}
          pageStatusIcon={
            <StatusBox type={ProjectStatus[status]}>{status}</StatusBox>
          }
          extraButtons={projectButtons}
        >
          <CardItem title="Translation Reviewer" span={6}>
            {reviewerEmail || '-'}
          </CardItem>
          <CardItem title="Current Translator" span={6}>
            {translatorEmail || '-'}
          </CardItem>
          <CardItem title="Multi-lingual Context Reference" span={12}>
            {contextReference || '-'}
          </CardItem>
        </Card>
        <Card
          title={`${selectedLang} Resource`}
          style={{ marginTop: 24 }}
          className={styles.resources}
          extraButtons={resourceButtons}
          pageStatusIcon={
            <StatusBox type={ResourceStatus[resStatus]}>{resStatus}</StatusBox>
          }
        >
          {rejectReason && (
            <AlertMessage
              type="error"
              message={rejectReason}
              style={{ marginBottom: 16, marginTop: 0 }}
            />
          )}
          <div className={styles.searchBar}>
            <Input
              prefix={<SearchOutlined style={{ fontSize: 24 }} />}
              className={styles.input}
              onPressEnter={(e) => handleFilter((e.target as any).value)}
            />
          </div>
          <Table
            dataSource={display || []}
            columns={[
              {
                title: 'No',
                dataIndex: 'id',
                render: (id: number, row: any, index: number) => index + 1,
              },
              {
                title: 'Item(EN)',
                dataIndex: 'name',
                className: styles.tableColumn,
              },
              {
                title: `Target - ${selectedLang}`,
                dataIndex: 'target',
                className: styles.tableColumn,
              },
              {
                title: 'Last Translated',
                dataIndex: 'lastTranslatedAt',
                render: (data) =>
                  data ? moment(data).format('DD/MM/YYYY HH:mm:ss') : '-',
              },
              {
                title: 'Last Translator',
                dataIndex: 'lastTranslator',
              },
            ]}
            rowKey="id"
          />
        </Card>
      </div>
      <BackTop />
    </Spin>
  );
};

export default ProjectDetail;
